import request from "./request";

export function getList(data) {
	return request({
		url: "/notice/list",
		method: "post",
		headers: { "Content-Type": "multipart/form-data" },
		data,
	});
}

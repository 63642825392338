<template>
  <div class="news-page view-page">
    <!-- 搜素 -->
    <!-- <c-search searchTitle="关键字搜索" valueFormat="yyyyMMdd" @search="searchAffiche" placeholder="关键字" :isBorder="isBorder" :isDate="true"></c-search> -->
    <!-- 分类 -->
    <!-- <c-navigation title="状态" :list="approvalList" :isBorder="false"></c-navigation> -->
    <!-- 表格 -->
    <c-table :data="tableData">
      <el-table-column align="center" prop="content" label="消息内容" width="460"></el-table-column>
      <el-table-column align="center" prop="createtime" label="创建时间"></el-table-column>
      <el-table-column align="center" prop="date" label="操作">
        <template #default="{ row }">
          <div class="table-operate">
            <p class="operate-list" :class="{ edit: row.isChecked }" @click="checkCase(row)">查看</p>
          </div>
        </template>
      </el-table-column>
    </c-table>

    <!-- 完成通知 -->
    <c-drawer v-model="showCaseFinishInform" :width="35" title="案件完成通知">
      <div class="caseContent">案件通知案件通知案件通知案件通知案件通知案件通知案件通知案件通知案 件通知</div>
    </c-drawer>

    <!-- 分页 -->
    <div class="page">
      <c-pagination @change="changePage" :pageSize="afficheParam.limit" :total="contactsTotal"></c-pagination>
    </div>
  </div>
</template>

<script>
import CDrawer from '@/components/c-drawer'
import * as afficheApi from '@/api/affiche'

export default {
  name: 'Affiche',
  components: {
    CDrawer
  },
  data() {
    return {
      isBorder: true, //是否显示下边框
      showCaseFinishInform: false, // 显示案件完成通知弹框
      selectedTable: [],
      // 消息提醒
      approvalList: [
        {
          id: 1,
          name: '未读',
          isChecked: true
        },
        {
          id: 2,
          name: '已读',
          isChecked: false
        }
      ],
      // 表格数据
      tableData: [],
      afficheParam: {
        page: 1,
        limit: 20
      },
      contactsTotal: 0
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.getList()
    },
    getList() {
      afficheApi.getList(this.afficheParam).then(res => {
        this.tableData = res.data.data
        this.contactsTotal = res.data.total
      })
    },
    /**
     * 跳转页数
     */
    changePage(i) {
      this.afficheParam.page = i
      this.getList()
    },
    // 选中的项
    checkedClient(row) {
      let idx
      this.selectedTable.forEach((item, index) => {
        if (item.id === row.id) {
          idx = index
        }
      })
      if (idx >= 0) {
        this.selectedTable.splice(idx, 1)
      } else {
        this.selectedTable.push(row)
      }
    },

    // 查看消息
    checkCase(e) {
      this.showCaseFinishInform = true
    },
    /**
     * 搜索
     */
    searchAffiche(e) {
      this.afficheParam.name = e.keyWord
      if (e.date) {
        this.afficheParam.starday = e.date[0]
        this.afficheParam.endday = e.date[1]
      } else {
        this.afficheParam.starday = ''
        this.afficheParam.endday = ''
      }
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.news-page {
  padding: 2rem 0;
  background: #ffffff;
}

.edit {
  color: #f57d0a;
}

.caseContent {
  padding: 1.5rem 1.9rem;
}
</style>
